import { isEmpty } from 'lodash';
import { forwardRef } from 'react';

import OutputSelect from 'components/output-select';
import Textarea from 'components/textarea';

export default forwardRef((props, ref) => {
  const { value = '', onChange = () => false, ...textAreaProps } = props;
  const handleAddOutput = output => {
    onChange([value, `$\{${output}}`].join(' '));
  };

  return (
    <>
      <Textarea
        ref={ref}
        {...textAreaProps}
        value={value}
        onChange={onChange}
        className="mb-2 text-sm"
      />
      <div className="flex flex-col">
        <div className="flex items-center gap-2">
          <label className="pt-6">Available Outputs</label>
          <div className="flex-1">
            <OutputSelect
              classNames={{
                manageLinkText: 'justify-end',
              }}
              onChange={selected => {
                if (!isEmpty(selected?.value)) handleAddOutput(selected.value);
              }}
              placeholder="Add to Message"
            />
          </div>
        </div>
      </div>
    </>
  );
});
