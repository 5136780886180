import { api, q } from 'config/api';

export default function useLatestSkillVersion(id, overrides) {
  return q.useQuery({
    queryKey: ['skill', id, 'latestSkillVersion'],
    queryFn: () =>
      api(
        `query latestSkillVersion($id: ID!) {
          skill(id: $id) {
            versions(list: { limit: 1 }) {
              data {
                id
                version
                repository {
                  uri
                  username
                  password
                }
                createOptions
                storage
                devices {
                  sound
                  hdmi
                  cameras
                }
                env
                outputs(excludeDefaults: true) {
                  id
                  label
                  mapping {
                    type
                    statusMapping {
                      upperThreshold
                      lowerThreshold
                      direction
                    }
                  }
                }
                inputs {
                  label
                  binding
                  type
                }
                createOptions
                storage
                hostName
                endpointAliases
                portBindings
                tmpfs
                shmSize
                port
                removableMedia
                hostNetworking
                ... on SkillVersion {
                  gpio
                  cx2000IRRemote
                  usbSerialConverter
                  cx2000VideoAcceleration
                  dockerInDocker
                  privileged
                  mountVolumes
                  led
                  binds
                  capAdd
                  capDrop
                  addedDevice
                  writableRootFS
                }
                devices {
                  sound
                  hdmi
                  cameras
                }
                env
                dockerCompose {
                  key
                }
              }
            }
          }
        }`,
        { id },
      ),
    enabled: !!id,
    // refetchInterval: 5000,
    ...overrides,
  });
}
