import { isBoolean, keyBy, truncate } from 'lodash';
import { camelCase } from 'lodash/fp';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Card, Button, Heading } from '@optra/kit';

import ActionTriggerRules from 'components/action-trigger-rules';
import ApiActionDataFields from 'components/api-action-data-fields';
import DynamicOutputFields from 'components/dynamic-output-fields';
import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import Select from 'components/select';
import SkillIcon from 'components/skill-icon';
import ValidationError from 'components/validation-error';
import { api, q, useOnSuccess } from 'config/api';
import { useInputFocus } from 'hooks';
import { useWorkflowAction } from 'queries';

export default function ApiActionForm({ workflowActionId, onClose }) {
  const {
    handleSubmit: onSubmit,
    reset,
    register,
    setFocus,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      configuration: {
        shared: {
          url: '',
          method: 'POST',
        },
      },
    },
  });
  const [error, setError] = useState();
  useInputFocus(setFocus, 'configuration.shared.url');

  const {
    data,
    isSuccess,
    isLoading: fetching,
    error: fetchError,
  } = useWorkflowAction(workflowActionId);

  useOnSuccess(
    () => {
      const configuration = {
        shared: {
          url: data?.workflowAction?.configuration?.shared?.url,
          method: data?.workflowAction?.configuration?.shared?.method,
        },
      };

      const triggerRules = data?.workflowAction?.triggerRules || [];
      const triggerRulesByWorkflowSkill = keyBy(triggerRules, 'workflowSkillId');

      data?.workflowAction?.workflow?.skills?.data?.forEach(workflowSkill => {
        configuration[workflowSkill?.id] = {
          payload: {},
          dynamicOutputs: data.workflowAction?.configuration?.[workflowSkill.id]?.dynamicOutputs,
        };

        workflowSkill?.outputs?.forEach(output => {
          const existingConfig =
            data?.workflowAction?.configuration?.[workflowSkill.id]?.payload?.[output.output.id];

          configuration[workflowSkill.id]['payload'][output.output.id] = {
            id: output.output.id,
            as: existingConfig?.as || camelCase(output.output.id),
            enabled: isBoolean(existingConfig?.enabled) ? existingConfig?.enabled : true,
          };
        });

        configuration[workflowSkill.id]['triggerRules'] =
          triggerRulesByWorkflowSkill[workflowSkill.id];
      });

      reset({
        configuration,
      });
    },
    { isSuccess },
  );

  const qc = q.useQueryClient();
  const updateWorkflowAction = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateWorkflowActionAPI ($form: updateWorkflowActionForm!) {
          workflowAction: updateWorkflowAction(form: $form) {
            id
            workflow {
              id
            }
          }
        }`,
        { form },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['workflowAction', workflowActionId] });
      qc.invalidateQueries({ queryKey: ['workflow', r?.workflowAction?.workflow?.id] });
      onClose();
    },
    onError(error) {
      setError(error);
    },
  });

  const handleSubmit = onSubmit(form => {
    setError(null);
    const configuration = { shared: form.configuration.shared };
    const triggerRules = [];

    Object.entries(form?.configuration)
      .filter(([key]) => key !== 'shared') // ignore shared config
      .forEach(([key, config]) => {
        // ensure triggerRules don't seep into the skill configuration
        configuration[key] = {
          payload: config.payload,
          dynamicOutputs: config.dynamicOutputs,
        };

        if (config.triggerRules?.conditions?.length > 0) {
          triggerRules.push({
            ...config.triggerRules,
            workflowSkillId: key,
          });
        }
      });

    updateWorkflowAction.mutate({
      id: workflowActionId,
      configuration,
      triggerRules,
    });
  });

  const loading = fetchError || fetching || updateWorkflowAction.isPending;

  return (
    <>
      <ModalBody as="form" onSubmit={handleSubmit} className="space-y-4">
        {error && (
          <Message variant="danger" title="Couldn't Save Action">
            {error.message}
          </Message>
        )}
        {fetchError && (
          <Message variant="danger" title="Couldn't Load Action">
            {fetchError.message}
          </Message>
        )}

        <Card variant="secondary" className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="configuration.shared.url">Enter Your API’s Endpoint URL</Label>
            <Input
              type="text"
              {...register('configuration.shared.url', {
                required: 'Please enter a URL.',
                pattern: {
                  value:
                    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
                  message: 'Please enter a valid URL',
                },
              })}
              readOnly={loading}
            />
            <ValidationError errors={errors} name="configuration.shared.url" />
          </div>

          <div className="space-y-2">
            <Label htmlFor="configuration.shared.method">HTTP Method</Label>
            <Select {...register('configuration.shared.method')} readOnly={loading}>
              <option value="POST">POST</option>
              <option value="GET">GET</option>
              <option value="PUT">PUT</option>
              <option value="DELETE">DELETE</option>
            </Select>
          </div>
        </Card>

        <Heading icon="Cube" level={3}>
          Skill Data
        </Heading>
        {(data?.workflowAction?.workflow?.skills?.data || [])
          .filter(ws => !ws.disabled)
          .map?.(workflowSkill => (
            <Card variant="secondary" key={workflowSkill.id}>
              <div className="flex items-center">
                <SkillIcon
                  size="sm"
                  color={workflowSkill?.skill?.color}
                  icon={workflowSkill?.skill?.icon}
                  iconUrl={workflowSkill?.skill?.iconUrl}
                  className="mr-3"
                />
                <Heading level={4}>
                  {truncate(workflowSkill?.name, {
                    length: 32,
                  })}
                </Heading>
              </div>

              <ActionTriggerRules
                control={control}
                register={register}
                skillId={workflowSkill.id}
                name={`configuration[${workflowSkill.id}].triggerRules`}
              />

              {(workflowSkill.outputs || [])
                .filter(({ disabled }) => !disabled)
                .map(({ output }) => (
                  <ApiActionDataFields
                    key={output.id}
                    register={register}
                    watch={watch}
                    output={output}
                    workflowSkill={workflowSkill}
                    loading={loading}
                    errors={errors}
                  />
                ))}

              <DynamicOutputFields
                control={control}
                register={register}
                name={`configuration[${workflowSkill.id}].dynamicOutputs`}
              />
            </Card>
          ))}
      </ModalBody>
      <ModalFooter>
        <Button size="xl" loading={loading} onClick={handleSubmit}>
          Save
        </Button>
      </ModalFooter>
    </>
  );
}
