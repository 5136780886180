import cx from 'classnames';
import { has, isEmpty, startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { Link, Routes, Route } from 'react-router-dom';

import { Icon, Text } from '@optra/kit';

import DropdownSelect from 'components/dropdown-select';
import Modal from 'components/modal';
import { api, q } from 'config/api';
import useDebouncedState from 'hooks/use-debounced-state';
import EditOutput from 'modals/edit-output';
import ManageOutputs from 'modals/manage-outputs';
import useOutputs from 'queries/use-outputs';

export default function OutputSelect(props) {
  const { hideManageLink = false, classNames, ...dropdownSelectProps } = props;
  const [showManageOutputs, setShowManageOutputs] = useState(false);

  const [$search, setSearch] = useDebouncedState('');
  const Outputs = useOutputs({
    filter: {
      $search,
    },
  });

  const qc = q.useQueryClient();
  const createOutput = q.useMutation({
    mutationFn: form =>
      api(
        `mutation createOutput($form: upsertOutputForm!) {
          createOutput(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['outputs'] });
    },
  });

  const options = Outputs.data?.map(output => ({ label: output.label, value: output.key })) || [];
  const [_value, setValue] = useState(props?.value);
  useEffect(() => {
    setValue(props?.value);
  }, [props?.value]);
  const value = has(_value, 'label') ? _value : options.find(opt => opt.value === _value?.value);

  return (
    <>
      <div className="relative">
        {!hideManageLink && (
          <Link
            to="./manage-outputs"
            onClick={() => setShowManageOutputs(true)}
            state={{
              fromModal: true,
            }}
          >
            <Text
              size="xs"
              color="muted"
              className={cx(
                'flex items-center cursor-pointer hover:underline mb-2',
                classNames?.manageLinkText,
              )}
            >
              <Icon name="Gear" size="xs" className="mr-1" /> Manage Outputs
            </Text>
          </Link>
        )}
        <div className="flex flex-col">
          <DropdownSelect
            maxMenuHeight="170px"
            {...dropdownSelectProps}
            value={value}
            onChange={selected => {
              setValue(selected);
              props?.onChange?.(selected);
            }}
            onInputChange={typedValue => setSearch(typedValue)}
            onCreateOption={newKey => {
              createOutput.mutate({ key: newKey });
              const created = { label: startCase(newKey), value: newKey };
              setValue(created);
              props?.onCreateOption?.({ ...created, __isNew__: true });
              props?.onChange?.({ ...created, __isNew__: true });
            }}
            isLoading={Outputs.isFetching && !Outputs.isFetched}
            allowCreateWhileLoading
            creatable
            isClearable
            formatCreateLabel={currentValue => `Create "${currentValue}"`}
            options={options}
            isValidNewOption={newOption => {
              if (isEmpty(newOption)) return false;
              if (newOption.includes(' ')) return false;
              if (Outputs.data?.map(o => o.key).includes(newOption)) return false;
              return true;
            }}
          />
        </div>
      </div>

      {showManageOutputs && (
        <Routes>
          <Route
            path="manage-outputs"
            element={
              <Modal>
                <ManageOutputs
                  onClose={() => {
                    setShowManageOutputs(false);
                  }}
                />
              </Modal>
            }
          />
          <Route
            path="manage-outputs/:outputKey/edit"
            element={
              <Modal>
                <EditOutput />
              </Modal>
            }
          />
        </Routes>
      )}
    </>
  );
}
