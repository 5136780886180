import { isArray, isEmpty, last } from 'lodash';
import Papa from 'papaparse';
import { useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useTitle } from 'react-use';

import { Button, FileInput, Text } from '@optra/kit';

import CsvDocs from 'components/csv-docs';
import List from 'components/list';
import ListItem from 'components/list-item';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import OrganizationSelect from 'components/organization-select';
import ValidationError from 'components/validation-error';
import { api, q } from 'config/api';
import { useInputFocus, useRouteQueryParams } from 'hooks';
import { useSignedUpload } from 'queries';

export default function AdminPreclaimedDevicesUpload() {
  useTitle('New Preclaimed Device | Optra');

  const { organizationId } = useRouteQueryParams();
  const [fileValidated, setFileValidated] = useState(false);
  const [fileChecking, setFileChecking] = useState(false);
  const [uploadedData, setUploadedData] = useState([]);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(organizationId);
  const { handleClose } = useModalContext();
  const { control, handleSubmit, register, reset, setError, setFocus, watch } = useForm({
    defaultValues: { file: null },
  });
  useInputFocus(setFocus, 'serialNumber');
  const selectedFile = watch('file');
  const [upload, uploadState] = useSignedUpload({ type: 'bulkPreclaimDevice' });

  const qc = q.useQueryClient();
  const preclaimedDevices = q.useMutation({
    mutationFn: form =>
      api(
        `mutation preclaimDevices($form: preclaimedDevicesForm!) {
          uploadResult: preclaimDevices(form: $form) {
            success
            errors
            warnings
          }
        }`,
        { form },
      ),
    onSuccess(response) {
      qc.invalidateQueries({ queryKey: ['preclaimedDevices'] });

      const error = response?.uploadResult?.errors?.length
        ? response.uploadResult.errors.join(', ')
        : response?.uploadResult?.error?.message;

      // TODO: Not all errors belong to the file input
      if (!isEmpty(error)) {
        setError('file', {
          type: 'manual',
          message: error,
        });
      }
    },
    onError(err) {
      setError('file', {
        type: 'manual',
        message: err.message,
      });
    },
  });
  const { errors } = useFormState({ control });
  const hasErrors = !isEmpty(errors);
  const submitting = uploadState?.fetching || preclaimedDevices.isPending;
  const currentFile = watch('file');
  const hasFile = currentFile?.length === 1;
  const fileRegistration = register('file', { required: true });

  // TODO: async functions should never be set in html handlers
  // This validation logic needs to be integrated into react query for proper lifecycle handling
  const validateFile = async e => {
    try {
      fileRegistration.onChange(e);
      setFileChecking(true);
      setFileValidated(false);
      const blob = e.currentTarget.files?.[0];

      if (!blob) {
        setError('file', {
          type: 'manual',
          message: 'CSV is required.',
        });
        return;
      }

      // NOTE: This is a good spot for showing "validation in progress" state
      await new Promise(resolve => {
        const uploadedPreclaimedDevices = [];
        return Papa.parse(blob, {
          worker: true,
          header: true,
          skipEmptyLines: true,
          step({ data, errors }, parser) {
            if (errors?.length && errors.every(e => e.type !== 'Delimiter')) {
              setError('file', {
                type: 'manual',
                message: `Row ${errors?.[0]?.row}: ${errors?.[0]?.message}`,
              });
              parser.abort();
              return;
            }

            if (isEmpty(data?.serialNumber)) {
              setError('file', {
                type: 'manual',
                message: 'Missing serial number value.',
              });
              parser.abort();
              return;
            }

            if (data?.serialNumber?.length !== 13) {
              setError('file', {
                type: 'manual',
                message: `${data.serialNumber} received ${data?.serialNumber?.length} digits instead of 13 digits`,
              });
              parser.abort();
              return;
            }

            uploadedPreclaimedDevices.push(data.serialNumber);
          },
          complete() {
            setUploadedData(uploadedPreclaimedDevices);

            if (!uploadedPreclaimedDevices?.length) {
              setError('file', {
                type: 'manual',
                message: 'Unable to read csv or format is invalid',
              });
            }
            resolve();
          },
        });
      });
    } catch (err) {
      console.error(err);
    } finally {
      setFileChecking(false);
      setFileValidated(true);
    }
  };

  // TODO: OrganizationSelect isn't properly integrated into react hook form (OrganizationSelect needs to be refactored to work normally)
  const handleOnSubmit = handleSubmit(async form => {
    if (fileChecking) {
      return;
    }

    if (selectedOrganizationId === 'all' || isEmpty(selectedOrganizationId)) {
      // TODO: this field should be a part of the form
      setError('organizationId', {
        type: 'manual',
        message: 'Organization is required',
      });

      return;
    }

    const blob = form.file[0];
    if (!blob) {
      setError('file', {
        type: 'manual',
        message: 'A CSV file is required',
      });

      return;
    }

    if (hasErrors) {
      return;
    }

    const { key } = await upload(blob, {
      extension: last(blob.name.split('.')),
    });

    preclaimedDevices.mutate(
      {
        key,
        organizationId: selectedOrganizationId,
      },
      {
        onSuccess(result) {
          if (result?.uploadResult?.success) {
            handleClose();
          }
        },
      },
    );
  });

  const handleReset = () => {
    reset();
    setFileValidated(false);
    setFileChecking(false);
    setSelectedOrganizationId(null);
    setUploadedData([]);
  };

  const isLoading = preclaimedDevices.isPending;
  const helpColumns = [
    {
      column: 'serialNumber',
      name: 'Serial Number',
      description: null,
      examples: ['8080000000001'],
      required: true,
    },
  ];

  let detail = 'Waiting for CSV file…';
  let error =
    !isEmpty(errors?.file?.message) && !isArray(errors?.file?.message) && errors.file.message;

  if (hasFile && fileValidated) {
    detail = 'CSV file ready for upload…';
  } else {
    if (fileChecking) {
      detail = 'Validating file…';
    } else if (uploadState?.fetching) {
      detail = `${uploadState.percent} Complete`;
    } else if (submitting) {
      detail = 'Processing file…';
    }
  }

  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle title="Upload List" icon="CloudArrowUp" />
      <ModalBody className="space-y-4">
        <div className="text-right">
          <OrganizationSelect
            placeholderText="Select an Organization"
            hideAllOption
            value={selectedOrganizationId}
            onChange={nextOrganizationId => setSelectedOrganizationId(nextOrganizationId)}
            filters={{ all: true }}
          />
        </div>
        <ValidationError errors={errors} name="organizationId" />
        <div className="flex-shrink-0">
          <FileInput
            prompt="Upload CSV File"
            result={selectedFile?.[0]}
            accept=".csv"
            {...fileRegistration}
            onChange={validateFile}
            detail={detail}
            error={error}
            loading={uploadState?.fetching}
          >
            {hasErrors && (
              <Button size="xs" variant="tertiary" onClick={handleReset}>
                Reset
              </Button>
            )}
          </FileInput>
        </div>
        <CsvDocs columns={helpColumns} />
        {!isEmpty(uploadedData) && (
          <div>
            <Text>Uploaded {uploadedData.length} Devices</Text>
            <List className="mt-2">
              {uploadedData.map(item => (
                <ListItem key={item}>
                  <div className="flex flex-row justify-between items-center space-y-2">
                    <Text variant="bold">{item}</Text>
                  </div>
                </ListItem>
              ))}
            </List>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={handleOnSubmit}
          type="submit"
          size="xl"
          disabled={
            !hasFile ||
            fileChecking ||
            isEmpty(uploadedData) ||
            isEmpty(selectedOrganizationId) ||
            selectedOrganizationId === 'all' ||
            !isEmpty(errors)
          }
          loading={isLoading}
        >
          Save Devices
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
