import { api, q } from 'config/api';

export default function useDevicesMap(variables) {
  return q.useInfiniteQuery({
    queryKey: ['devicesMap', variables],
    queryFn({ pageParam }) {
      return api(
        `query devicesMap($list: listFields, $outputKey: String) {
          list: devicesMap(list: $list, outputKey: $outputKey) {
            data
            cursor {
              after
            }
          }
        }`,
        {
          ...variables,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    refetchInterval: 1000 * 60 * 10, // every 10 minutes
  });
}
