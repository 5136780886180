import { set } from 'lodash/fp';
import { useSessionStorage } from 'usehooks-ts';

import { api, q } from 'config/api';
import useDevicesColumns from 'hooks/use-devices-columns';
import useMapStateOutput from 'hooks/use-mapState-output';
import { useOrganizationClient } from 'providers/organization-provider';

export default function useCurrentOrganization(overrides) {
  const { reset: resetDevicesColumns } = useDevicesColumns()[2];
  const { reset: resetMapStateOutput } = useMapStateOutput()[2];
  const [, setAssumedOrganizationId] = useSessionStorage('assumedOrganizationId', null);
  const [selectedOrganizationId, client] = useOrganizationClient();
  const {
    data,
    isLoading: fetching,
    isSuccess,
  } = q.useQuery({
    queryKey: ['currentOrganization'],
    queryFn: () =>
      api(
        `query currentOrganization {
          organization: currentOrganization {
            id
            name
            pricing
          }
        }`,
      ),
    ...overrides,
  });

  function handleChange(orgId, redirectPath) {
    client.set(orgId);
    setAssumedOrganizationId(null);
    resetDevicesColumns();
    resetMapStateOutput();
    const cache = JSON.parse(localStorage.getItem('REACT_QUERY_OFFLINE_CACHE'));
    const nextQueries = cache.clientState.queries.filter(({ queryKey }) => {
      return !queryKey.includes('roles');
    });
    const nextCache = set('clientState.queries', nextQueries, cache);
    localStorage.setItem('REACT_QUERY_OFFLINE_CACHE', JSON.stringify(nextCache));
    if (redirectPath) {
      window.location.href = redirectPath;
    } else {
      window.location.reload();
    }
  }

  return [
    {
      id: selectedOrganizationId || '$$NONE',
      ...data?.organization,
    },
    {
      fetching,
      isSuccess,
      handleChange,
    },
  ];
}
