import { Badge, Button, Icon, Text, Heading } from '@optra/kit';

import CompatibilityBadge from 'components/compatibility-badge';
import SkillIcon from 'components/skill-icon';
import { creditsToFormattedString } from 'lib/format-credits';
import { useCurrentOrganization, useCurrentUser } from 'queries';

export default function MarketplaceSkillHeader(props) {
  const { fetching = true, marketplaceSkill = {}, hideAddBtn } = props;
  const { purchased, owned, requiresContact } = marketplaceSkill;
  const [organization] = useCurrentOrganization();
  const [currentUser] = useCurrentUser();

  const subject = `Marketplace Skill Request - ${marketplaceSkill?.name}`;
  const body = `I would like to receive access to ${
    marketplaceSkill?.name
  } (Marketplace Skill) with ID ${marketplaceSkill.id}. ${
    organization.id !== '$$NONE'
      ? `My organization is ${organization.name} (${organization.id}) and you can reach me at ${currentUser.email}`
      : `My account is ${currentUser.email} (${currentUser.id})`
  }`;

  return (
    <div className="rounded-t-lg bg-light-bg-secondary dark:bg-dark-bg-secondary">
      <div
        className="h-48 rounded-t-lg overflow-hidden flex flex-col items-center justify-end bg-light-bg-tertiary dark:bg-dark-bg-tertiary"
        style={{
          clipPath: 'polygon(50% 100%, 100% 60%, 100% 0, 0 0, 0 60%)',
        }}
      >
        <div
          className="absolute inset-0 opacity-40"
          style={{
            backgroundColor: marketplaceSkill?.color,
            backgroundImage: `url(${marketplaceSkill?.iconUrl})`,
            backgroundSize: '100%',
            filter: 'blur(50px)',
          }}
        />
      </div>

      <div className="-mt-14 mb-6 relative px-12 flex flex-col lg:flex-row items-center lg:items-end lg:justify-between space-y-4 lg:space-y-0">
        <div className="flex-1 pb-4 order-3 lg:order-none">
          <CompatibilityBadge
            compatibleDeviceModels={marketplaceSkill?.latestVersion?.compatibleDeviceModels}
          />
        </div>

        <div className="flex flex-2 flex-col items-center justify-center order-1 lg:order-none">
          <SkillIcon
            size="lg"
            color={marketplaceSkill?.color}
            icon={marketplaceSkill?.icon}
            iconUrl={marketplaceSkill?.iconUrl}
            isPro={marketplaceSkill?.latestVersion?.creditsCost > 0}
            className="mb-2"
            fetching={fetching}
          />

          <div className="text-center">
            <Heading>{marketplaceSkill?.name || 'Loading…'}</Heading>
          </div>

          {marketplaceSkill?.developerProfile?.name && (
            <div className="text-center">
              <Text size="sm" color="muted">
                by {marketplaceSkill?.developerProfile?.name}
              </Text>
            </div>
          )}
        </div>

        <div className="flex flex-1 flex-row justify-end pb-4 space-x-3 order-2 lg:order-none">
          {!hideAddBtn && (
            <>
              {!fetching && marketplaceSkill?.purchased === true && (
                <Badge variant="muted">
                  <Icon name="Check" size="sm" className="mr-1" /> In Library
                </Badge>
              )}
              {!fetching && !purchased && !owned && !requiresContact && (
                <Button icon="Plus" to="./subscribe" state={{ fromModal: true }}>
                  Add to Library
                </Button>
              )}
              {/* TODO: Link to email client */}
              {!fetching && !purchased && !owned && requiresContact && (
                <Button
                  href={`mailto:optra.help@lexmark.com?subject=${subject}&body=${body}`}
                  icon="Envelope"
                >
                  Contact Us
                </Button>
              )}
            </>
          )}
        </div>
      </div>

      {marketplaceSkill?.latestVersion?.creditsCost > 0 && (
        <div className="px-6 lg:px-12">
          <div className="flex flex-row items-center rounded-md p-4 my-2 text-sm bg-yellow">
            <Icon
              name="ShieldCheck"
              weight="fill"
              className="text-yellow-50 dark:text-black mr-1"
            />
            <Text className="text-black">This is a PRO skill that requires</Text>
            <Badge variant="inverted" className="mx-2">
              <Icon name="Coins" className="text-yellow-500 mr-1" />
              <Text className="text-yellow-500">
                {creditsToFormattedString(marketplaceSkill?.latestVersion?.creditsCost)}
              </Text>
            </Badge>
            <Text className="text-black">credits per device per month to run.</Text>
          </div>
        </div>
      )}
    </div>
  );
}
