import cx from 'classnames';
import { Controller } from 'react-hook-form';

import ActionTriggerRules from 'components/action-trigger-rules';
import DataMessageInput from 'components/data-message-input';
import Input from 'components/input';
import Label from 'components/label';
import ValidationError from 'components/validation-error';

export default function FirebaseActionMessageFields({
  configurationField,
  index,
  register,
  errors,
  control,
  watch,
  loading,
  skillId,
}) {
  const enabled = watch(`configurationFields.${index}.enabled`);

  return (
    <div className={cx(['space-y-4', enabled ? 'block' : 'hidden'])}>
      <input type="hidden" {...register(`configurationFields.${index}.id`)} />

      <div className="space-y-2">
        <Label htmlFor={`configurationFields.${index}.title`}>Title</Label>
        <Input
          type="text"
          placeholder="Enter notification title"
          {...register(`configurationFields.${index}.title`, {
            validate: value => (enabled && value.trim() === '' ? 'Please enter a title.' : true),
          })}
        />
        <ValidationError errors={errors} name={`configurationFields.${index}.title`} />
      </div>

      <div className="space-y-2">
        <Label htmlFor={`configurationFields.${index}.message`}>Message</Label>
        <Controller
          render={({ field }) => {
            return (
              <DataMessageInput
                rows={8}
                disabled={loading}
                outputs={configurationField?.outputs}
                placeholder="Type your message..."
                {...field}
              />
            );
          }}
          name={`configurationFields.${index}.message`}
          control={control}
          rules={{
            validate: value => (enabled && value.trim() === '' ? 'Please enter a message.' : true),
          }}
        />
        <ValidationError errors={errors} name={`configurationFields.${index}.message`} />
      </div>

      <ActionTriggerRules
        control={control}
        register={register}
        skillId={skillId}
        name={`configurationFields.${index}.triggerRules`}
      />
    </div>
  );
}
