import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import { Button, Table } from '@optra/kit';

import EmptyContentMessage from 'components/empty-content-message';
import IntersectionLoadMore from 'components/intersection-load-more';
import Message from 'components/message';
import Modal from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import { api, q } from 'config/api';
import { useOrganizationClient } from 'providers/organization-provider';
import { useOrganizations, useCurrentUser } from 'queries';

export default function CopySkill() {
  useTitle('Copy Skill | Optra');
  const { skillId } = useParams();
  const navigate = useNavigate();
  const [currentUser] = useCurrentUser();
  const isSysAdmin = currentUser?.isSysAdmin || false;

  const [currentOrganizationId] = useOrganizationClient();
  const { data, fetchNextPage, isFetched, isLoading, hasNextPage, isFetchingNextPage } =
    useOrganizations({ all: isSysAdmin });
  const organizations = data?.filter(organization => organization.id !== currentOrganizationId);
  const [selected, setSelected] = useState([]);
  const [error, setError] = useState();

  const handleSelect = orgId => {
    if (selected.includes(orgId)) {
      setSelected(selected.filter(id => id !== orgId));
    } else {
      setSelected([...selected, orgId]);
    }
  };
  const copySkillToOrgs = q.useMutation({
    mutationFn: ({ skillIdToCopy, toOrgs }) => {
      return api(
        `mutation copySkillToOrgs($form: copySkillToOrgsForm!) {
          copySkillToOrgs(form: $form)
        }`,
        { form: { skillId: skillIdToCopy, toOrgs } },
      );
    },
    onSuccess() {
      navigate('..');
    },
    onError(err) {
      setError(err);
    },
  });
  const onSubmit = () => {
    if (selected.length < 1) {
      setError({ message: 'Please select at least one organization' });
    } else {
      setError(null);
      copySkillToOrgs.mutate({ skillIdToCopy: skillId, toOrgs: selected });
    }
  };
  return (
    <Modal>
      <ModalInner>
        <ModalTitle title="Copy Skill To" icon="Copy" />
        <ModalBody>
          {error && (
            <Message variant="danger" title="Failed to copy skill">
              {error.message}
            </Message>
          )}
          <Table>
            <Table.Body>
              {!isEmpty(organizations) &&
                organizations.map(organization => (
                  <Table.Row
                    key={organization.id}
                    selectable={true}
                    hover={true}
                    onSelect={() => handleSelect(organization.id)}
                    onClick={() => handleSelect(organization.id)}
                    selected={selected.includes(organization.id)}
                  >
                    <Table.TD>{organization.name}</Table.TD>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
          <IntersectionLoadMore
            onVisible={fetchNextPage}
            disabled={isLoading || isFetchingNextPage || !hasNextPage}
          />
        </ModalBody>
        {isEmpty(organizations) && isFetched && (
          <EmptyContentMessage icon="Buildings" title="No Organizations Available" />
        )}
        <ModalFooter>
          <Button
            type="submit"
            size="xl"
            loading={isLoading || copySkillToOrgs.isPending}
            onClick={onSubmit}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalInner>
    </Modal>
  );
}
