import { useSessionStorage } from 'usehooks-ts';

import { useFeature } from 'components/feature';
import devicesTableColumns from 'config/devices-table-columns';

const defaultValue = [devicesTableColumns[0], devicesTableColumns[1], devicesTableColumns[2]];

export default function useDevicesColumns() {
  const deviceConnectionEnabled = useFeature('deviceConnection');
  const [_value, setValue] = useSessionStorage('devices.visibleColumns.v1', defaultValue);

  const extensions = {
    reset() {
      setValue(defaultValue);
    },
    toggleColumn(column) {
      setValue(prev => {
        const exists = prev.find(col => col.id === column.id);
        if (exists) return prev.filter(col => col.id !== column.id);
        return [...prev, column];
      });
    },
  };

  const value = deviceConnectionEnabled
    ? _value
    : _value.filter(column => !['connectionStatus', 'downtime'].includes(column.id));

  return [value, setValue, extensions];
}
