import { isEmpty } from 'lodash';
import chunk from 'lodash/chunk';

import { Button } from '@optra/kit';

import { q } from 'config/api';

export default function DownloadLogsButton(props) {
  const { loading, logs: lines } = props || {};
  const ready = !loading;

  const download = q.useMutation({
    async mutationFn() {
      if (!ready || isEmpty(lines)) return;

      const CHUNK_SIZE = 1000;
      const chunkedLines = chunk(lines.reverse(), CHUNK_SIZE);

      try {
        if ('showSaveFilePicker' in window) {
          const fileHandle = await window.showSaveFilePicker({
            suggestedName: 'logs.txt',
          });

          const writableStream = new WritableStream({
            async write(chunk) {
              const text = chunk.join('\n') + '\n';
              const encoded = new TextEncoder().encode(text);
              await fileWritable.write(encoded);
            },
            async close() {
              await fileWritable.close();
            },
            abort(err) {
              console.error('WritableStream aborted with error:', err);
            },
          });

          const fileWritable = await fileHandle.createWritable();
          const writer = writableStream.getWriter();

          for (const chunk of chunkedLines) {
            await writer.write(chunk);
          }

          await writer.close();
        } else {
          throw new Error('File System Access API is not supported');
        }
      } catch (error) {
        // Fallback to Blob if File System Access is not available
        let result = '';
        for (const chunk of chunkedLines) {
          result += chunk.join('\n') + '\n';
        }

        const blob = new Blob([result], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'logs.txt';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }
    },
  });

  return (
    <Button
      variant="secondary"
      size="xs"
      onClick={() => download.mutate()}
      icon="FileArrowDown"
      iconWeight="line"
      loading={download.isPending}
      disabled={loading || isEmpty(lines)}
    >
      Download Logs
    </Button>
  );
}
