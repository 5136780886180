import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Card, Button, Icon, Text } from '@optra/kit';

import Feature from 'components/feature';
import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalTitle from 'components/modal-title';
import ValidationError from 'components/validation-error';
import { useInputFocus } from 'hooks';
import { useProfile, useProvisionedDeviceBySerialNumber } from 'queries';

const DEFAULT_VALUES = {
  serialNumber: '',
  returnAuthorization: '',
};

export default function DeviceCreateSerialNumber() {
  const Profile = useProfile();
  const isCreditOwner = Profile.data?.currentOwner?.pricing === 'credit';

  const navigate = useNavigate();
  const [error, setError] = useState();
  const [showRma, setShowRma] = useState(false);

  const {
    handleSubmit: onSubmit,
    register,
    formState: { errors },
    setFocus,
    watch,
  } = useForm({
    defaultValues: DEFAULT_VALUES,
  });
  useInputFocus(setFocus, 'serialNumber');
  const serialNumber = watch('serialNumber');

  const {
    data,
    isFetching: fetching,
    error: fetchError,
    refetch,
  } = useProvisionedDeviceBySerialNumber(serialNumber, { enabled: false });

  const handleSubmit = onSubmit(async form => {
    setError(null);
    const {
      data: { provisionedDevice },
    } = await refetch();

    if (provisionedDevice && !provisionedDevice.claimable) {
      navigate(`./${provisionedDevice?.id}/claim`, {
        state: { fromModal: true },
      });
    }

    if (provisionedDevice && provisionedDevice.claimable && !isCreditOwner) {
      navigate(`./${provisionedDevice?.id}/details`, {
        state: { fromModal: true },
      });
    }

    if (provisionedDevice && provisionedDevice.claimable && isCreditOwner) {
      navigate(`./${provisionedDevice?.id}/subscription`, {
        state: { fromModal: true },
      });
    }
  });

  useEffect(() => {
    if (!fetching && serialNumber && data?.provisionedDevice === null) {
      setError({ message: 'Check your serial number and try again.' });
    }
  }, [data?.provisionedDevice, fetching, serialNumber]);

  return (
    <>
      <ModalTitle
        title="Enroll Device"
        icon="Aperture"
        loading={fetching}
        renderActions={() => {
          return (
            <Feature feature="bulkEnroll">
              <Button
                size="xs"
                variant="secondary"
                to={'/devices/create-bulk'}
                state={{ fromModal: true }}
                icon="Cards"
              >
                Bulk Upload
              </Button>
            </Feature>
          );
        }}
      />
      <ModalBody as="form" onSubmit={handleSubmit}>
        {error && (
          <Message variant="danger" title="Device Not Found">
            {error.message}
          </Message>
        )}
        {fetchError && (
          <Message variant="danger" title="Error Loading Device">
            {fetchError.message}
          </Message>
        )}

        <Card variant="secondary" className="space-y-3">
          <div className="space-y-2">
            <Label htmlFor="serialNumber">Device Serial Number</Label>
            <Input
              type="text"
              {...register('serialNumber', { required: 'Please enter a serial number.' })}
              readOnly={fetching}
              className="uppercase font-mono"
            />
            <ValidationError errors={errors} name="serialNumber" />
          </div>

          <div className="space-y-2">
            <Text
              size="xs"
              color="muted"
              className="underline cursor-pointer flex items-center"
              onClick={() => setShowRma(!showRma)}
            >
              <Icon name={showRma ? 'CaretDown' : 'CaretRight'} size="xs" className="mr-1" />
              Have an RMA Number?
            </Text>
            {showRma && (
              <div className="space-y-2">
                <Label htmlFor="returnAuthorization">RMA Number</Label>
                <Input type="text" {...register('returnAuthorization')} readOnly={fetching} />
              </div>
            )}
          </div>
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={handleSubmit}
          type="submit"
          size="xl"
          loading={fetching}
          disabled={Profile.isLoading}
        >
          Next
        </Button>
      </ModalFooter>
    </>
  );
}
