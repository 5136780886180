import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Text } from '@optra/kit';

import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalTitle from 'components/modal-title';
import compute500 from 'images/setup-diagram-compute-500.png';
import compute550 from 'images/setup-diagram-compute-550.png';
import cx1000 from 'images/setup-diagram-cx1000.png';
import cx2000 from 'images/setup-diagram-cx2000.png';
import vz1000 from 'images/setup-diagram-vz1000.png';
import vz5000 from 'images/setup-diagram-vz5000.png';
import vz5100 from 'images/setup-diagram-vz5100.png';
import vz6100 from 'images/setup-diagram-vz6100.png';
import { useProfile, useProvisionedDevice } from 'queries';

const images = {
  compute500,
  compute550,
  cx1000,
  cx2000,
  vz1000,
  vz5000,
  vz5100,
  vz6100,
  vz6000: vz6100,
};

export default function DeviceCreateClaim() {
  const Profile = useProfile();
  const isCreditOwner = Profile.data?.currentOwner?.pricing === 'credit';

  const navigate = useNavigate();
  const { handleClose } = useModalContext();
  const { provisionedDeviceId } = useParams();
  const {
    data,
    isFetching: fetching,
    error: fetchError,
    refetch,
  } = useProvisionedDevice(provisionedDeviceId);

  const isClaimable = data?.provisionedDevice?.claimable;
  useEffect(() => {
    if (isClaimable && isCreditOwner) {
      navigate(`../${provisionedDeviceId}/subscription`, {
        state: { fromModal: true },
      });
    }

    if (isClaimable && !isCreditOwner) {
      navigate(`../${provisionedDeviceId}/details`, {
        state: { fromModal: true },
      });
    }
  }, [isClaimable, isCreditOwner, provisionedDeviceId, navigate]);

  function handleClaim() {
    refetch();
  }

  return (
    <>
      <ModalTitle
        title="Enroll Device"
        icon="Aperture"
        handleClose={() => {
          if (window.confirm('Are you sure you want to cancel the enrollment process?')) {
            handleClose();
          }
        }}
      />
      <ModalBody className="text-center">
        {fetchError && (
          <Message variant="danger" title="Error Loading Device">
            {fetchError.message}
          </Message>
        )}

        <div
          style={{
            height: 260,
            backgroundImage: `url(${images[data?.provisionedDevice?.model]})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />

        <Text>
          Press and release the “setup” button and wait until the{' '}
          {data?.provisionedDevice?.model === 'cx2000' ? '"status"' : '"power"'} light stops
          flashing, then click the button below.
        </Text>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleClaim} loading={fetching} size="xl">
          Claim Device
        </Button>
      </ModalFooter>
    </>
  );
}
