import { noop } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { Icon, Table, Text } from '@optra/kit';

import Message from 'components/message';
import { parseWholeCredits } from 'lib/format-credits';

export default function AdminPurchaseOrderDetail({
  error,
  purchaseOrder,
  giftCards = [],
  selectedGiftCards = [],
  onSelectGiftCard = noop,
}) {
  const navigate = useNavigate();

  if (error) {
    return (
      <Message variant="danger" title="Couldn't Load Purchase Order">
        {error.message}
      </Message>
    );
  }

  return (
    <Table>
      <Table.Body>
        {giftCards.length ? (
          giftCards.map(card => (
            <Table.Row
              key={card.id}
              hover
              caret
              selectable
              disabledSelectable={card?.isRevoked}
              selected={selectedGiftCards.includes(card.id)}
              onSelect={() => onSelectGiftCard(card.id)}
              onClick={() =>
                navigate(
                  `/admin/usage-credits/purchase-order/${purchaseOrder.id}/gift-card/${card.id}`,
                )
              }
            >
              <Table.TD className="flex flex-row">
                {card?.isRevoked ? (
                  <Icon name="Prohibit" className="mr-4" />
                ) : card?.isRedeemed ? (
                  <Icon name="CheckCircle" className="mr-4" weight="fill" />
                ) : (
                  <Icon name="Clock" className="mr-4" />
                )}
                <Text variant="bold">{card?.code}</Text>
              </Table.TD>
              <Table.TD>
                <Text color="muted">{purchaseOrder?.vendor}</Text>
              </Table.TD>
              <Table.TD>
                <Text color="muted">{parseWholeCredits(card?.amount || 0)} credits</Text>
              </Table.TD>
            </Table.Row>
          ))
        ) : (
          <Table.Row>
            <Table.TD>
              <Text>No gift cards available</Text>
            </Table.TD>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
}
