import { keyBy } from 'lodash';

import { api, q } from 'config/api';

export default function useDevices(variables, overrides) {
  return q.useInfiniteQuery({
    queryKey: ['devices', variables],
    queryFn({ pageParam }) {
      return api(
        `query devices($list: listFields, $outputKey: String) {
          list: devices(list: $list) {
            count
            data {
              id
              createdAt
              name
              model
              status
              autoClaim
              location {
                name
                lat
                lng
                address {
                  address
                  locality
                }
              }
              tags {
                data {
                  tag {
                    id
                    name
                  }
                }
              }
              workflows {
                data {
                  id
                  name
                  skills {
                    data {
                      id
                      name
                      version {
                        version
                      }
                      skill {
                        id
                        name
                        icon
                        iconUrl
                        color
                      }
                    }
                  }
                }
              }
              firmware {
                version
                releaseNotes
                updateAvailable
                isUpdating
                latestAvailableFirmware {
                  version
                  releaseNotes
                }
              }
              downtime {
                down
                occurrences
                downAt
                recoveredAt
              }
              disk {
                usage
              }
              ram {
                usage
              }
              cpu {
                usage
              }
              ip
              mac
              headphoneJack
              internalTemperatureUsage
              serialNumber
              provisionedDevice {
                warrantyStartedAt
              }
              subscriptionStatus
              mapState(outputKey: $outputKey)
              outputs: latestOutputs {
                id
                key
                name
                value
                type
                healthy
              }
            }
            cursor {
              after
            }
          }
        }`,
        {
          ...variables,
          list: {
            ...variables?.list,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: lastPage => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    refetchInterval: 60000,
    select: data => {
      return {
        ...data,
        pages: data.pages.map(page => ({
          ...page,
          list: {
            ...page.list,
            data: page.list.data.map(datum => ({
              ...datum,
              outputs: keyBy(datum.outputs, 'key'),
            })),
          },
        })),
      };
    },
    ...overrides,
  });
}
