import { useEffect, useMemo, useState } from 'react';

import { Button, DayPicker, Modal } from '@optra/kit';

export default function TemporalDateRangeSelector(props) {
  const {
    handleSelectRange,
    open,
    reset,
    selectButtonText = 'Select',
    onClose,
    min,
    max,
    minDate,
  } = props;
  const today = useMemo(() => new Date(), []);
  const INITIAL_RANGE = useMemo(() => ({ from: today, to: today }), [today]);
  const [range, setRange] = useState(INITIAL_RANGE);

  useEffect(() => {
    if (reset) {
      setRange(INITIAL_RANGE);
    }
  }, [INITIAL_RANGE, reset]);

  return (
    <Modal isOpen={open} onClose={() => onClose?.(false)}>
      <Modal.Header heading="Select Date Range" />
      <Modal.Body className="flex flex-col items-center">
        <DayPicker
          mode="range"
          min={min}
          max={max}
          minDate={minDate}
          maxDate={today}
          selected={range}
          onDateChange={setRange}
        />
      </Modal.Body>
      <Modal.Footer className="justify-center space-x-2">
        {range !== INITIAL_RANGE && (
          <Button variant="secondary" onClick={() => setRange(INITIAL_RANGE)}>
            Reset
          </Button>
        )}
        <Button
          variant="secondary"
          onClick={() => {
            setRange({});
            handleSelectRange({});
            onClose?.(false);
          }}
        >
          Clear
        </Button>
        <Button
          disabled={!range?.from || !range?.to}
          onClick={() => {
            handleSelectRange({
              from: range.from.setHours(0, 0, 0),
              to: range.to.setHours(23, 59, 59),
            });
            onClose?.(false);
          }}
        >
          {selectButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
