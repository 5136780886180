import cx from 'classnames';
import { useState, forwardRef, useEffect } from 'react';
import { Controller, useWatch } from 'react-hook-form';

import { ButtonWrap, WhatsThis, Toggle, Card, Button, Icon } from '@optra/kit';

import Input from 'components/input';
import Label from 'components/label';
import Select from 'components/select';

const StatusMappingEntry = forwardRef(
  ({ focused, onFocus, onBlur, onChange, disabled, defaultValue, type, position }, ref) => {
    const isAutoUpdate = position === 1 && type === 'boolean';

    return (
      <div className={cx('text-center', '-mt-5', type === 'number' ? 'w-20' : 'w-24')}>
        {(type === 'number' || type === 'boolean') && (
          <Icon
            name="CaretUp"
            className={cx(
              '-mb-3',
              focused ? 'text-primary' : 'dark:text-gray-800 text-gray-200',
              isAutoUpdate && 'text-opacity-50',
            )}
          />
        )}
        {type === 'number' ? (
          <Input
            type="text"
            className="bg-gray-200 border-gray-200 dark:bg-gray-800 dark:border-gray-800 focus:border-primary focus:ring-primary text-center"
            variant="none"
            onFocus={onFocus}
            onChange={e =>
              onChange(isNaN(parseFloat(e.currentTarget.value)) ? null : +e.currentTarget.value)
            }
            onBlur={onBlur}
            ref={ref}
            disabled={disabled}
            value={defaultValue}
            onKeyPress={e => {
              if (!/[0-9.]/.test(e.key)) e.preventDefault();
            }}
          />
        ) : (
          <Select
            onFocus={onFocus}
            onBlur={onBlur}
            variant="none"
            className="bg-gray-200 border-gray-200 dark:bg-gray-800 dark:border-gray-800 focus:border-primary focus:ring-primary text-center rounded-md"
            onChange={e => onChange(e.currentTarget.value === 'true')}
            disabled={disabled}
            value={defaultValue}
            ref={ref}
            {...(isAutoUpdate && { value: defaultValue, disabled: true })}
          >
            <option value="" disabled></option>
            <option value="true">True</option>
            <option value="false">False</option>
          </Select>
        )}
      </div>
    );
  },
);

const StatusMappingDirection = forwardRef(({ direction, onClick, disabled }, ref) => (
  <ButtonWrap type="button" onClick={onClick} disabled={disabled}>
    <Icon
      size="lg"
      weight="regular"
      variant="secondary"
      name={direction === 'asc' ? 'ArrowFatLinesRight' : 'ArrowFatLinesLeft'}
      className="align-middle opacity-60"
    />
  </ButtonWrap>
));

function NumberStatusMapping({ direction, onDirectionClick, disabled }) {
  return (
    <>
      <div
        className={cx(
          'flex-1 h-3 rounded-full',
          direction === 'desc' ? 'bg-green' : 'bg-red',
          disabled && 'opacity-60',
        )}
      />
      <div className={cx('flex-1 h-3 bg-yellow rounded-full', disabled && 'opacity-60')} />
      <div
        className={cx(
          'flex-1 h-3 rounded-full',
          direction === 'desc' ? 'bg-red' : 'bg-green',
          disabled && 'opacity-60',
        )}
      />
      <StatusMappingDirection
        direction={direction}
        onClick={onDirectionClick}
        disabled={disabled}
      />
    </>
  );
}

function BooleanStatusMapping({ disabled }) {
  return (
    <>
      <div className={cx('flex-1 h-3 rounded-full bg-red', disabled && 'opacity-60')} />
      <div className={cx('flex-1 h-3 rounded-full bg-green', disabled && 'opacity-60')} />
    </>
  );
}

export default function OutputFields({ form, loading, errors }) {
  const { register, setValue, control } = form;
  const tracked = useWatch({ control, name: 'tracked' });
  const mappingType = useWatch({ control, name: 'mapping.type' });
  const statusMapping = useWatch({ control, name: 'mapping.statusMapping' });
  const direction = useWatch({ control, name: 'mapping.statusMapping.direction' });
  const unhealthyTimeoutEnabled = useWatch({ control, name: 'unhealthyTimeoutEnabled' });

  const [focusedStatus, setFocusedStatus] = useState('');

  const statusMappingEnabled = Boolean(
    statusMapping &&
      statusMapping.lowerThreshold !== undefined &&
      statusMapping.upperThreshold !== undefined,
  );

  useEffect(() => {
    if (!tracked) {
      setValue('mapping', null);
    } else {
      setValue('mapping.type', mappingType || 'string');

      if (mappingType === 'string') {
        setValue('mapping.statusMapping', null);
      }
    }
  }, [tracked, setValue, mappingType]);

  useEffect(() => {
    if (statusMappingEnabled && !statusMapping) {
      setValue('mapping.statusMapping', {
        lowerThreshold: mappingType === 'number' ? 0 : false,
        upperThreshold: mappingType === 'number' ? 1 : true,
        direction: mappingType === 'number' ? 'asc' : null,
      });
    }
  }, [mappingType, statusMappingEnabled, setValue, statusMapping]);

  const toggleStatusMapping = () => {
    const isEnabled = !statusMappingEnabled;
    if (isEnabled) {
      setValue(
        'mapping.statusMapping',
        statusMapping || {
          lowerThreshold: mappingType === 'number' ? 0 : false,
          upperThreshold: mappingType === 'number' ? 1 : true,
          direction: mappingType === 'number' ? 'asc' : null,
        },
      );
    } else {
      setValue('mapping.statusMapping', null);
    }
  };

  const toggleDirection = () => {
    setValue('mapping.statusMapping.direction', direction === 'asc' ? 'desc' : 'asc');
  };

  const getLowerThresholdDefault = mappingType => {
    const lowerThreshold = statusMapping?.lowerThreshold;
    return lowerThreshold !== undefined
      ? lowerThreshold
      : mappingType === 'number'
      ? 0
      : mappingType === 'boolean'
      ? false
      : '';
  };

  const getUpperThresholdDefault = mappingType => {
    const upperThreshold = statusMapping?.upperThreshold;
    return upperThreshold !== undefined
      ? upperThreshold
      : mappingType === 'number'
      ? 1
      : mappingType === 'boolean'
      ? true
      : '';
  };

  const renderNumberEntries = () => {
    if (direction === 'asc') {
      return (
        <>
          <StatusMappingEntry
            key={0}
            onFocus={() => setFocusedStatus('0')}
            onBlur={() => setFocusedStatus(undefined)}
            focused={focusedStatus === '0'}
            onChange={value => setValue('mapping.statusMapping.lowerThreshold', value)}
            defaultValue={getLowerThresholdDefault(mappingType)}
            type={mappingType || 'number'}
            disabled={loading}
          />
          {/* Upper Threshold Entry */}
          <StatusMappingEntry
            key={1}
            onFocus={() => setFocusedStatus('1')}
            onBlur={() => setFocusedStatus(undefined)}
            focused={focusedStatus === '1'}
            onChange={value => setValue('mapping.statusMapping.upperThreshold', value)}
            defaultValue={getUpperThresholdDefault(mappingType)}
            type={mappingType || 'number'}
            disabled={loading}
          />
        </>
      );
    }

    return (
      <>
        {/* Upper Threshold Entry (inverted order) */}
        <StatusMappingEntry
          key={1}
          onFocus={() => setFocusedStatus('1')}
          onBlur={() => setFocusedStatus(undefined)}
          focused={focusedStatus === '1'}
          onChange={value => setValue('mapping.statusMapping.upperThreshold', value)}
          defaultValue={getUpperThresholdDefault(mappingType)}
          type={mappingType || 'number'}
          disabled={loading}
        />
        {/* Lower Threshold Entry (inverted order) */}
        <StatusMappingEntry
          key={0}
          onFocus={() => setFocusedStatus('0')}
          onBlur={() => setFocusedStatus(undefined)}
          focused={focusedStatus === '0'}
          onChange={value => setValue('mapping.statusMapping.lowerThreshold', value)}
          defaultValue={getLowerThresholdDefault(mappingType)}
          type={mappingType || 'number'}
          disabled={loading}
        />
      </>
    );
  };

  const renderBooleanEntries = () => {
    return (
      <>
        {/* Lower Threshold Entry (inverted order) */}
        <StatusMappingEntry
          key={0}
          onFocus={() => setFocusedStatus('0')}
          onBlur={() => setFocusedStatus(undefined)}
          focused={focusedStatus === '0'}
          onChange={value => setValue('mapping.statusMapping.lowerThreshold', value)}
          defaultValue={getLowerThresholdDefault(mappingType)}
          type={mappingType || 'number'}
          disabled={loading}
        />
        {/* Upper Threshold Entry (inverted order) */}
        <StatusMappingEntry
          key={1}
          onFocus={() => setFocusedStatus('1')}
          onBlur={() => setFocusedStatus(undefined)}
          focused={focusedStatus === '1'}
          onChange={value => setValue('mapping.statusMapping.upperThreshold', value)}
          defaultValue={getUpperThresholdDefault(mappingType)}
          type={mappingType || 'number'}
          disabled={loading}
        />
      </>
    );
  };

  return (
    <div className="space-y-3">
      <Card variant="secondary" className="flex space-x-4">
        <div className="flex-1 grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label htmlFor="output-key">Output Key</Label>
            <Input
              type="text"
              {...register('key', {
                required: 'Output Key is required',
                validate: v => v.trim() !== '',
              })}
              disabled={loading}
              defaultValue={useWatch({ control, name: 'key' })}
            />
            {errors.key && <p className="text-red-500 text-sm">{errors.key.message}</p>}
          </div>

          <div className="space-y-2">
            <Label htmlFor="output-label">Output Label</Label>
            <Input
              type="text"
              {...register('label', {
                required: 'Output Label is required',
                validate: v => v.trim() !== '',
              })}
              disabled={loading}
              defaultValue={useWatch({ control, name: 'label' })}
            />
            {errors.label && <p className="text-red-500 text-sm">{errors.label.message}</p>}
          </div>

          <label className="space-y-2">
            <Label as="div" className="flex items-center space-x-2">
              <span>Track Health</span>
              <WhatsThis>
                Check if an output with cadence has not been received within some amount of time
              </WhatsThis>
            </Label>
            <Controller
              name="unhealthyTimeoutEnabled"
              control={control}
              render={({ field }) => (
                <Toggle
                  as="div"
                  name={field.name}
                  checked={field.value || false}
                  onChange={() => field.onChange(!field.value)}
                  disabled={loading}
                />
              )}
            />
          </label>

          <div className="space-y-2">
            <Label htmlFor="unhealthyTimeoutMS">Unhealthy After (ms)</Label>
            <Input
              type="number"
              {...register('unhealthyTimeoutMS', {
                setValueAs: v => parseInt(v, 10) || 0,
              })}
              disabled={loading || !unhealthyTimeoutEnabled}
              defaultValue={useWatch({ control, name: 'unhealthyTimeoutMS' })}
            />
          </div>

          <label className="space-y-2">
            <Label as="div" className="flex items-center space-x-2">
              <span>Track Status</span>
              <WhatsThis>Tracked outputs can be used for filtering and sorting devices.</WhatsThis>
            </Label>
            <Controller
              name="tracked"
              control={control}
              render={({ field }) => (
                <Toggle
                  as="div"
                  name={field.name}
                  checked={field.value || false}
                  onChange={() => field.onChange(!field.value)}
                  disabled={loading}
                />
              )}
            />
          </label>

          <div className={cx('space-y-2', tracked ? '' : 'hidden')}>
            <Label htmlFor="mapping-type">Data Type</Label>
            <Controller
              name="mapping.type"
              control={control}
              render={({ field }) => (
                <Select {...field} disabled={loading}>
                  <option value="" disabled>
                    Select Data Type...
                  </option>
                  <option value="string">String</option>
                  <option value="number">Number</option>
                  <option value="boolean">Boolean</option>
                </Select>
              )}
            />
          </div>

          <div className={cx('space-y-2 col-span-2', !tracked && 'hidden')}>
            <Button
              variant="secondary"
              size="xs"
              onClick={toggleStatusMapping}
              disabled={loading || mappingType === 'string' || !mappingType || !tracked}
            >
              {statusMappingEnabled ? 'Disable Status Mapping' : 'Enable Status Mapping'}
            </Button>
          </div>

          {(statusMappingEnabled || statusMapping) && tracked && (
            <div className={cx('col-span-2 space-y-2')}>
              <div className={cx('flex items-center -mb-2')}>
                {mappingType === 'number' && (
                  <NumberStatusMapping
                    direction={direction || 'asc'}
                    onDirectionClick={toggleDirection}
                    disabled={loading}
                  />
                )}
                {mappingType === 'boolean' && <BooleanStatusMapping disabled={loading} />}
              </div>
              <div
                className="flex justify-between"
                style={
                  mappingType === 'number'
                    ? {
                        marginLeft: 'calc((100% - 32px) / 3 - 2.5rem)',
                        marginRight: 'calc((100% + 64px) / 3 - 2.5rem)',
                      }
                    : {
                        marginLeft: 'calc(100% / 4 - 3rem)',
                        marginRight: 'calc(100% / 4 - 3rem)',
                        marginTop: '1rem',
                      }
                }
              >
                {mappingType === 'number' && renderNumberEntries()}
                {mappingType === 'boolean' && renderBooleanEntries()}
              </div>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}
