import cx from 'classnames';
import { isEmpty } from 'lodash';
import { Route, Routes, useLocation } from 'react-router-dom';

import { Card, Badge, Button, Heading, Text } from '@optra/kit';

import DeviceImage from 'components/device-image';
import Modal from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import PortalSubscriptionFeatures from 'components/portal-subscription-features';
import { api, q } from 'config/api';
import { creditsToFormattedString } from 'lib/format-credits';
import AddCredits from 'modals/add-credits';
import { useCurrentBillingAccount } from 'queries';

export default function DeviceSubscription(props) {
  const { deviceId, provisionedDeviceId, onComplete, btnText = 'Next' } = props;
  const { pathname } = useLocation();

  const billingAccount = useCurrentBillingAccount();
  const currentBillingAccount = billingAccount?.data?.currentBillingAccount;
  const { balance } = currentBillingAccount?.balances || 0;

  const isProvisionedDevice = isEmpty(deviceId);
  const id = isProvisionedDevice ? provisionedDeviceId : deviceId;
  const type = isProvisionedDevice ? 'provisionedDevice' : 'device';

  const { data } = q.useQuery({
    queryKey: ['creditCost', id, type],
    queryFn: () =>
      api(
        `query deviceSubscriptionData($id: ID!, $type: CreditPriceType!) {
          creditPrice(id: $id, type: $type)
          ${
            !isProvisionedDevice
              ? `device(id: $id) {
                  id
                  model
                  serialNumber
                }`
              : `device: provisionedDevice(id: $id) {
                  id
                  model
                  serialNumber
                }`
          }
        }`,
        { id, type },
      ),
    retry: 0,
  });

  const creditsRequired = data?.creditPrice;
  const percentAvailable = Math.min((balance / creditsRequired) * 100, 100);
  const insufficientCredits = balance < creditsRequired;

  return (
    <>
      <ModalInner>
        <ModalBody className="space-y-6">
          <Card variant="secondary" className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center space-x-4">
              <DeviceImage model={data?.device?.model} hideStatus />
              <div>
                <Heading level={2}>Portal Subscription</Heading>
                <div className="flex flex-row items-center space-x-1">
                  <Text size="sm" color="muted" className="font-mono">
                    {data?.device?.serialNumber}
                  </Text>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-end">
              <Badge>{`${creditsToFormattedString(creditsRequired)} Credits`}</Badge>
            </div>
          </Card>

          <PortalSubscriptionFeatures />

          <Card variant="secondary" className="flex flex-col items-center justify-center space-y-2">
            <Heading level={2}>Credit Balance</Heading>
            <div className="bg-light-bg-tertiary dark:bg-light-bg-secondary w-full h-4 rounded-full">
              <div
                style={{ width: `${percentAvailable}%` }}
                className={cx([
                  'rounded-full',
                  'h-4',
                  percentAvailable >= 100 && 'bg-green',
                  percentAvailable < 100 && 'bg-red',
                ])}
              />
            </div>
            <Text>
              {`${creditsToFormattedString(balance)} / ${creditsToFormattedString(
                creditsRequired,
              )}`}
            </Text>
            <Button
              to={`./add-credits?returnTo=${pathname}`}
              state={{ fromModal: true }}
              icon="Plus"
              variant="tertiary"
            >
              Add Credits
            </Button>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button disabled={insufficientCredits} onClick={onComplete} size="xl">
            {btnText}
          </Button>
        </ModalFooter>
      </ModalInner>
      <Routes>
        <Route
          path="add-credits/*"
          element={
            <Modal>
              <AddCredits />
            </Modal>
          }
        />
      </Routes>
    </>
  );
}
