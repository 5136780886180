import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Button } from '@optra/kit';

import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import OutputFields from 'components/output-fields';
import { api, q } from 'config/api';
import useOutput from 'queries/use-output';

export default function EditOutput() {
  const { outputKey } = useParams();
  const form = useForm();
  const { handleClose } = useModalContext();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
  } = form;

  const qc = q.useQueryClient();

  const { data, isLoading } = useOutput(outputKey);
  const outputData = data?.output;

  useEffect(() => {
    if (outputData) {
      setValue('key', outputData.key);
      setValue('label', outputData.label);
      setValue('unhealthyTimeoutEnabled', !!outputData.unhealthyTimeoutEnabled);
      setValue('unhealthyTimeoutMS', outputData.unhealthyTimeoutMS ?? 3600000);
      setValue('tracked', !!outputData.tracked);

      setValue('mapping.type', outputData.mapping?.type);

      if (outputData.mapping?.statusMapping) {
        setValue('mapping.statusMapping', outputData.mapping.statusMapping);
      } else {
        setValue('mapping.statusMapping', null);
      }
    }
  }, [outputData, setValue]);

  const upsertOutput = q.useMutation({
    mutationFn: form =>
      api(
        `mutation upsertOutput($form: upsertOutputForm!) {
          output: upsertOutput(form: $form) {  
            id
            key
            label
            tracked
            unhealthyTimeoutMS
            unhealthyTimeoutEnabled
            mapping {
              type
              statusMapping {
                upperThreshold
                lowerThreshold
                direction
              }
            }
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['outputs'] }); // Re-fetch the list of outputs after upsert
    },
    onError(error) {
      setError('form', { message: error.message || 'Failed to upsert output. Please try again.' });
    },
  });

  const onSubmit = form => {
    upsertOutput.mutate(
      { ...form },
      {
        // Wrap formData in an object with `form` as the key
        onSuccess: updatedOutput => {
          console.log('Output successfully upserted:', updatedOutput);
        },
        onError: error =>
          setError('form', {
            message: error.message || 'Failed to upsert output. Please try again.',
          }),
      },
    );
  };

  const deleteOutput = q.useMutation({
    mutationFn: key =>
      api(
        `mutation deleteOutput($key: String!) {
          output: removeOutput(key: $key) {
            id
            key
            label
          }
        }`,
        { key },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['outputs'] });
      handleClose();
    },
    onError(error) {
      setError('form', { message: error.message || 'Failed to delete output. Please try again.' });
    },
  });

  const loading = isLoading || upsertOutput.isPending || deleteOutput.isPending;

  return (
    <ModalInner>
      <ModalTitle
        title="Edit Output"
        icon="MonitorPlay"
        showBackButton={true}
        renderActions={() => (
          <Button
            size="xs"
            variant="secondary"
            onClick={() => {
              if (window.confirm('Are you sure you want to delete this output?')) {
                deleteOutput.mutate(outputKey);
              }
            }}
            loading={deleteOutput.isLoading}
            icon="Trash"
          >
            Delete
          </Button>
        )}
      />
      <ModalBody className="space-y-4">
        {errors.form && <p className="text-red-500">{errors.form.message}</p>}
        <OutputFields form={form} loading={loading} control={control} errors={errors} />
      </ModalBody>
      <ModalFooter>
        <Button type="submit" onClick={handleSubmit(onSubmit)} loading={loading} size="xl">
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
