import { GoogleMap, Marker } from '@react-google-maps/api';
import cx from 'classnames';
import * as date from 'date-fns';
import { useMemo } from 'react';
import { useTitle } from 'react-use';

import {
  ControlBar,
  Dropdown,
  DROPDOWN_MENU_PLACEMENT,
  Badge,
  Card,
  Button,
  Spinner,
  Text,
  StatusIndicator,
  CopyButton,
} from '@optra/kit';

import DeviceDetailOutputsCard from 'components/device-detail-outputs-card';
import DeviceImage from 'components/device-image';
import { statePin } from 'components/devices-map-marker';
import EWSButton from 'components/ews-button';
import { useFeature } from 'components/feature';
import FirmwareVersionLabel from 'components/firmware-version-label';
import ModalBody from 'components/modal-body';
import { formatStatusLabel } from 'components/status-label';
import { api, q } from 'config/api';
import { defaultLocation } from 'config/constants';
import mapStyles from 'config/map-styles';
import modelNames from 'config/model-names';
import useMapStateOutput from 'hooks/use-mapState-output';
import { statStatus, headphoneLabel, headphoneStatus, statPercentageUsed } from 'lib/device-stats';
import useOutputs from 'queries/use-outputs';
import useGlobalState from 'state';

function StatItem({ label, value, status, copyValue }) {
  return (
    <div className="flex items-center justify-between mb-2">
      <Text size="sm" className="flex-1">
        {label}
      </Text>

      <div className="flex items-center justify-end flex-1">
        <StatusIndicator status={status} size="xs" />
        <Text size="sm" color="muted" className="ml-2">
          {value}
        </Text>
        {copyValue && <CopyButton value={copyValue} variant="tertiary" />}
      </div>
    </div>
  );
}

export default function DeviceDetailOverview({ deviceId }) {
  useTitle('Device Overview | Optra');
  const [mapScriptLoaded] = useGlobalState('mapScriptLoaded');
  const [appliedColorScheme] = useGlobalState('appliedColorScheme');
  const [selectedOutput, setSelectedOutput] = useMapStateOutput();
  const deviceConnectionEnabled = useFeature('deviceConnection');
  const skillInputOutputEnabled = useFeature('skillInputOutput');

  const { data, isLoading } = q.useQuery({
    queryKey: ['device', deviceId, 'detail', selectedOutput?.key ?? ''],
    queryFn: () =>
      api(
        `query device($deviceId: ID!, $outputKey: String) {
          device(id: $deviceId) {
            id
            createdAt
            name
            model
            status
            adminConnected
            autoClaim
            serialNumber
            location {
              lat
              lng
              address {
                address
                locality
              }
            }
            tags {
              data {
                tag {
                  id
                  name
                }
              }
            }
            firmware {
              version
              releaseNotes
              updateAvailable
              isUpdating
              latestAvailableFirmware {
                version
                releaseNotes
              }
            }
            downtime {
              down
              occurrences
              downAt
              recoveredAt
            }
            disk {
              usage
              availablePercent
            }
            ram {
              usage
              availablePercent
            }
            cpu {
              usage
              avgLoadPercent15Min
            }
            ip
            mac
            headphoneJack
            internalTemperature
            internalTemperatureUsage
            mapState(outputKey: $outputKey)
          }
        }`,
        {
          deviceId,
          outputKey: selectedOutput?.key,
        },
      ),
  });

  const [lat, lng, mapState] = useMemo(() => {
    return [
      data?.device?.location?.lat ?? defaultLocation.lat,
      data?.device?.location?.lng ?? defaultLocation.lng,
      data?.device?.mapState ?? '',
    ];
  }, [data?.device?.location?.lat, data?.device?.location?.lng, data?.device?.mapState]);

  const Outputs = useOutputs({ filter: { tracked: true } });

  const rebootDevice = q.useMutation({
    enabled: !!deviceId,
    mutationFn: () =>
      api(
        `mutation rebootDevice($form: runDeviceFnForm!) {
          runDeviceFn(form: $form) {
            id
          }
        }`,
        {
          form: {
            id: deviceId,
            fn: 'reboot',
          },
        },
      ),
  });

  const pingDevice = q.useMutation({
    enabled: !!deviceId,
    mutationFn: () =>
      api(
        `mutation pingDevice($form: runDeviceFnForm!) {
          runDeviceFn(form: $form) {
            id
          }
        }`,
        {
          form: {
            id: deviceId,
            fn: 'ping',
          },
        },
      ),
  });

  const handleReboot = () => {
    if (window.confirm('Are you sure you want to reboot this device?')) {
      rebootDevice.mutate();
    }
  };

  const handlePing = () => {
    pingDevice.mutate();
  };

  return (
    <ModalBody size="lg">
      <div
        className={cx([
          // Base
          'rounded',
          'shadow-xl',
          'border',
          'mb-6',
          'relative',
          // Light
          'bg-light-bg-primary',
          'border-light-fg-tertiary',
          // Dark
          'dark:bg-dark-bg-primary',
          'dark:border-dark-fg-tertiary',
        ])}
      >
        {mapScriptLoaded && (
          <GoogleMap
            mapContainerStyle={{
              height: '100%',
              minHeight: 260,
              width: '100%',
              borderRadius: 4,
            }}
            zoom={10}
            center={{
              lat,
              lng,
            }}
            options={{
              disableDefaultUI: true,
              gestureHandling: true,
              zoomControl: true,
              styles: mapStyles({ darkMode: appliedColorScheme === 'dark' }),
            }}
          >
            <Marker
              options={{
                position: {
                  lat,
                  lng,
                },
                icon: {
                  url: statePin(mapState),
                  size: new google.maps.Size(16, 16),
                  origin: new google.maps.Point(0, 0),
                  scaledSize: new google.maps.Size(12, 12),
                  anchor: new google.maps.Point(6, 6),
                },
              }}
            />
          </GoogleMap>
        )}
        <ControlBar sticky={false} full={false} className="absolute top-3 right-3">
          {isLoading || Outputs.isLoading ? (
            <Spinner className="w-7 h-7" color="gradient" />
          ) : (
            <div className="flex items-center flex-nowrap animate-fade-in">
              <Dropdown placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_RIGHT}>
                <Dropdown.Button size="xs">{selectedOutput.label}</Dropdown.Button>
                <Dropdown.Body unstyled>
                  <Dropdown.Item
                    text="Connection Status"
                    active={selectedOutput.key === ''}
                    onClick={() => setSelectedOutput({ label: 'Connection', key: '' })}
                  />
                  {Outputs.data?.map(output => (
                    <Dropdown.Item
                      key={output.id}
                      text={output.key}
                      active={selectedOutput.key === output.key}
                      onClick={() => setSelectedOutput({ label: output.label, key: output.key })}
                    />
                  ))}
                </Dropdown.Body>
              </Dropdown>
            </div>
          )}
        </ControlBar>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <Card
          variant="secondary"
          className={cx('space-y-2', !skillInputOutputEnabled && 'md:col-span-2')}
        >
          <div className="flex items-center justify-between">
            <Text>{data?.device?.name}</Text>
            <DeviceImage model={data?.device?.model} size="sm" hideStatus />
          </div>

          {data?.device?.tags?.data?.map(tag => (
            <Badge size="xs" variant="secondary" key={tag?.tag?.id} className="mr-2">
              {tag?.tag?.name}
            </Badge>
          ))}

          <div className="flex items-center">
            <Text size="xs" color="muted" className="block font-mono pt-1">
              {`Serial No: ${data?.device?.serialNumber}`}
            </Text>
            <CopyButton value={data?.device?.serialNumber} variant="tertiary" />
          </div>

          <Text size="xs" color="muted" className="block font-mono">
            {`Model: ${modelNames[data?.device?.model]}`}
          </Text>

          <div className="flex items-center">
            <Text size="xs" color="muted" className="font-mono">
              Firmware:
            </Text>
            <FirmwareVersionLabel
              to="./firmware"
              updateAvailable={data?.device?.firmware?.updateAvailable}
              updating={data?.device?.firmware?.isUpdating}
              version={data?.device?.firmware?.version}
            />
          </div>
        </Card>

        {skillInputOutputEnabled && <DeviceDetailOutputsCard deviceId={deviceId} />}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card variant="secondary">
          <div className="flex items-center justify-between mb-5">
            <Text variant="label">Network</Text>
            <Button
              icon="Broadcast"
              variant="tertiary"
              size="xs"
              onClick={handlePing}
              loading={pingDevice.isPending}
              hideChildrenWhenLoading={false}
            >
              Ping Device
            </Button>
          </div>
          {deviceConnectionEnabled && (
            <>
              <StatItem
                label="Network Connection"
                status={data?.device?.status}
                value={formatStatusLabel(data?.device.status)}
              />
              <StatItem
                label="Downtime"
                status={data?.device?.downtime?.down ? 'disabled' : 'enabled'}
                value={
                  data?.device?.downtime?.down
                    ? date.formatDistanceToNow(new Date(data?.device.downtime?.downAt))
                    : '--'
                }
              />
            </>
          )}
          <StatItem
            label="IP Address"
            status={data?.device?.ip ? 'enabled' : 'disabled'}
            value={data?.device?.ip || '--'}
            copyValue={data?.device?.ip?.split?.('/')?.[0]}
          />
          <StatItem
            label="MAC Address"
            status={data?.device?.mac ? 'enabled' : 'disabled'}
            value={data?.device?.mac || '--'}
            copyValue={data?.device?.mac}
          />
        </Card>

        <Card variant="secondary">
          <div className="flex items-center justify-between mb-5">
            <Text variant="label">Hardware</Text>
            <div>
              <EWSButton deviceId={deviceId} />
              <Button
                icon="Power"
                iconWeight="line"
                variant="tertiary"
                size="xs"
                onClick={handleReboot}
                loading={rebootDevice.isPending}
                hideChildrenWhenLoading={false}
                disabled={!data?.device.adminConnected}
              >
                Reboot Device
              </Button>
            </div>
          </div>

          <StatItem
            label="Disk Usage"
            status={statStatus(data?.device?.disk?.usage)}
            value={statPercentageUsed(data?.device?.disk?.availablePercent)}
          />
          <StatItem
            label="CPU Usage"
            status={statStatus(data?.device?.cpu?.usage)}
            value={`${data?.device?.cpu?.avgLoadPercent15Min || 0}%`}
          />
          <StatItem
            label="Memory Usage"
            status={statStatus(data?.device?.ram?.usage)}
            value={statPercentageUsed(data?.device?.ram?.availablePercent)}
          />
          <StatItem
            label="Audio Connection"
            status={headphoneStatus(data?.device?.headphoneJack)}
            value={headphoneLabel(data?.device?.headphoneJack)}
          />
          <StatItem
            label="Internal Temperature"
            status={statStatus(data?.device?.internalTemperatureUsage)}
            value={
              data?.device?.internalTemperature ? `${data?.device?.internalTemperature}º` : '--'
            }
          />
        </Card>
      </div>
    </ModalBody>
  );
}
