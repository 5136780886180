"use strict";
Object.defineProperty(exports, "giftCardPurchaseOrderCreate", {
    enumerable: true,
    get: function() {
        return giftCardPurchaseOrderCreate;
    }
});
const _zod = require("zod");
const giftCardPurchaseOrderCreate = _zod.z.object({
    codesToGenerate: _zod.z.preprocess((value)=>Number(value), _zod.z.number().int().positive('Codes to generate must be greater than zero')),
    creditsPerCode: _zod.z.preprocess((value)=>Number(value), _zod.z.number().positive('Credits per code must be greater than zero').min(0.01, 'Credits per code must be at least 0.01')),
    poNumber: _zod.z.string().min(1, 'Purchase order number is required'),
    vendor: _zod.z.string().min(1, 'Vendor is required')
});
