import { useNavigate } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';

import { api, q } from 'config/api';
import { useOrganizationClient } from 'providers/organization-provider';

import useDevicesColumns from './use-devices-columns';
import useMapStateOutput from './use-mapState-output';

export default function useImpersonation() {
  const qc = q.useQueryClient();

  const { reset: resetDevicesColumns } = useDevicesColumns()[2];
  const { reset: resetMapStateOutput } = useMapStateOutput()[2];
  const [assumedOrganizationId, setAssumedOrganizationId] = useSessionStorage(
    'assumedOrganizationId',
    null,
  );
  const [, organizationClient] = useOrganizationClient();
  const navigate = useNavigate();

  const ImpersonationSession = q.useQuery({
    queryKey: ['currentImpersonationSession'],
    queryFn: () =>
      api(
        `query currentImpersonationSession {
          session: currentImpersonationSession {
            user: assumedUser {
              id
              email
              name
              image
              isSysAdmin
              agreements {
                eula {
                  state
                  acceptedAt
                }
              }
            }
          }
        }`,
      ),
    retry: 0,
    select: data => data?.session?.user,
  });

  const stopImpersonation = q.useMutation({
    mutationFn: () =>
      api(
        `mutation stopCurrentImpersonationSession {
          stopCurrentImpersonationSession {
            stopDate
          }
        }`,
      ),
    onMutate() {
      resetDevicesColumns();
      resetMapStateOutput();
    },
    async onSuccess() {
      await qc.invalidateQueries({ queryKey: ['currentImpersonationSession'] });
      setAssumedOrganizationId(null);
      organizationClient.unset();
      window.location.reload();
    },
  });

  const impersonateUser = q.useMutation({
    mutationFn: ({ userProfileId, organizationId }) =>
      api(
        `mutation startImpersonationSession($form: startImpersonationSessionForm!) {
          session: startImpersonationSession(form: $form) {
            authorizedUserId
            assumedUserId
            startDate
            expireDate
          }
        }`,
        { form: { userProfileId, organizationId } },
      ),
    onMutate({ organizationId }) {
      resetDevicesColumns();
      resetMapStateOutput();
      organizationClient.set(organizationId);
      setAssumedOrganizationId(organizationId);
    },
    async onSuccess() {
      await qc.invalidateQueries({ queryKey: ['currentImpersonationSession'] });
      navigate('/');
      window.location.reload();
    },
  });

  const impersonateOrganization = q.useMutation({
    mutationFn: () =>
      api(
        `mutation stopCurrentImpersonationSession {
          stopCurrentImpersonationSession {
            stopDate
          }
        }`,
      ),
    onMutate(id) {
      setAssumedOrganizationId(id);
      organizationClient.set(id);
      resetDevicesColumns();
      resetMapStateOutput();
    },
    async onSuccess() {
      await qc.invalidateQueries({ queryKey: ['currentImpersonationSession'] });
      navigate('/');
      window.location.reload();
    },
  });

  return {
    stopImpersonation,
    impersonateUser,
    impersonateOrganization,
    assumedUser: ImpersonationSession,
    assumedOrganizationId,
  };
}
