import { api, q } from 'config/api';

export default function useOutputs(listFields, overrides) {
  return q.useInfiniteQuery({
    queryKey: ['outputs', listFields],
    queryFn({ pageParam }) {
      return api(
        `query outputs($list: listFields) {
          list: outputs(list: $list) {
            data {
              id
              key
              label
              tracked
              mapping {
                type
                statusMapping {
                  upperThreshold
                  lowerThreshold
                  direction
                }
              }
              unhealthyTimeoutMS
              unhealthyTimeoutEnabled
            }
            cursor {
              after
            }
          }
        }`,
        {
          list: {
            ...listFields,
            cursor: { after: pageParam },
          },
        },
      );
    },
    initialPageParam: null,
    getNextPageParam: (lastPage, pages) => lastPage.list?.cursor?.after,
    placeholderData: q.keepPreviousData,
    select: data => data.pages.flatMap(page => page.list.data),
    ...overrides,
  });
}
