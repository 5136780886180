import { api, q } from 'config/api';

export default function useOutput(key, overrides) {
  return q.useQuery({
    queryKey: ['output', key],
    queryFn() {
      return api(
        `query output($key: String!) {
          output(key: $key) {
            id
            key
            label
            tracked
            mapping {
              type
              statusMapping {
                upperThreshold
                lowerThreshold
                direction
              }
            }
            unhealthyTimeoutMS
            unhealthyTimeoutEnabled
          }
        }`,
        {
          key,
        },
      );
    },
    placeholderData: q.keepPreviousData,
    ...overrides,
  });
}
