// $1 USD = 10_000 OC
// $0.01 USD = 100 OC
// $0.0001 USD = 1 OC

import { takeRight } from 'lodash';

const precision = 2; // a penny is 2 orders of magnitude larger than a credit
const pennyToDollarPrecision = 2;

function parseCredits(credits = 0) {
  const str = `${credits}`;
  const totalCents = str.slice(0, Math.max(0, str.length - precision));
  const microcents = takeRight(str, precision).join('');

  const dollars = totalCents.slice(0, Math.max(0, totalCents.length - pennyToDollarPrecision));
  const cents = takeRight(totalCents, pennyToDollarPrecision).join('');
  const totalDollars = `${dollars}.${cents}`;

  return {
    dollars: Number(dollars),
    cents: Number(cents),
    totalCents: Number(totalCents),
    totalDollars: Number(totalDollars),
    microcents: Number(microcents),
    credits: Number(totalCents),
    creditsCents: Number(microcents),
  };
}

export function wholeCredits(amount) {
  return amount * 100;
}

export function parseWholeCredits(credits = 0) {
  const creditObject = parseCredits(credits);
  const result = creditObject.credits + creditObject.microcents / 100;
  return result.toLocaleString();
}

export function creditsToFormattedString(credits = 0) {
  return parseCredits(credits).credits.toLocaleString();
}

export function creditsToFormattedCurrency(credits = 0) {
  return parseCredits(credits).totalDollars.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
}
