import { useSessionStorage } from 'usehooks-ts';

const defaultValue = {
  label: 'Connection', // used by devices-header-bar
  key: '',
};

export default function useMapStateOutput() {
  const [value, setValue] = useSessionStorage('device.mapState.outputKey.v1', defaultValue);
  const extensions = {
    reset() {
      setValue(defaultValue);
    },
  };

  return [value, setValue, extensions];
}
