import { isEmpty, isFinite as _isFinite, last, omit, isBoolean } from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import { Button } from '@optra/kit';

import Message from 'components/message';
import { useModalContext } from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import RawConfig from 'components/raw-config';
import SkillContainerFields from 'components/skill-container-fields';
import SkillEnvFields from 'components/skill-env-fields';
import SkillFormTabs, { isActiveTab } from 'components/skill-form-tabs';
import SkillInputFields from 'components/skill-input-fields';
import SkillJsonUploadButton from 'components/skill-json-upload-button';
import SkillMetaFields from 'components/skill-meta-fields';
import SkillOutputFields from 'components/skill-output-fields';
import SkillPrivilegesFields from 'components/skill-privileges-fields';
import ValidationError from 'components/validation-error';
import { api, q } from 'config/api';
import { useInputFocus } from 'hooks';
import cleanInputArray from 'lib/clean-input-array';
import { useCurrentUser, useSignedUpload } from 'queries';

export default function CreateSkillDockerImage() {
  useTitle('Create Skill | Optra');
  const { workflowId } = useParams();
  const navigate = useNavigate();
  const { handleClose } = useModalContext();
  const form = useForm({
    defaultValues: {
      name: '',
      version: '1.0.0',
      icon: {
        color: '#00C425',
        icon: 'action',
      },
    },
  });
  const {
    control,
    formState: { errors },
    handleSubmit: onSubmit,
    register,
    setFocus,
    setValue,
  } = form;
  useInputFocus(setFocus, 'name');

  const [error, setError] = useState();
  const [tab, setTab] = useState('container');
  const [currentUser] = useCurrentUser();

  const [uploadIcon, uploadIconState] = useSignedUpload({ type: 'skillImage' });
  const qc = q.useQueryClient();
  const createSkill = q.useMutation({
    mutationFn: async mutationForm => {
      const result = await api(
        `mutation createSkillVersion($form: createSkillVersionForm!) {
          version: createSkillVersion(form: $form) {
            id
            skill {
              id
            }
          }
        }`,
        { form: { ...mutationForm, skill: omit(mutationForm.skill, 'iconUrl') } },
      );

      let skillId = result?.version?.skill?.id;

      let iconUrl = null;
      let iconFile = mutationForm.skill.iconUrl;
      if (iconFile instanceof File) {
        const uploadedIcon = await uploadIcon(iconFile, {
          extension: last(iconFile.name.split('.')),
          name: `${skillId}-icon`,
        });
        iconUrl = uploadedIcon.url;
      }

      await api(
        `mutation updateSkill($form: updateSkillForm!) {
          skill: updateSkill(form: $form) {
            id
          }
        }`,
        { form: { id: skillId, iconUrl } },
      );

      return result;
    },
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['skill', r.version?.skill?.id] });
      qc.invalidateQueries({ queryKey: ['skillVersion', r.version?.id] });

      qc.invalidateQueries({ queryKey: ['librarySkills'] });
      qc.invalidateQueries({ queryKey: ['librarySkill', r.version?.skill?.id] });
      qc.invalidateQueries({ queryKey: ['librarySkillVersion', r.version?.id] });

      if (workflowId) {
        navigate(`/workflows/${workflowId}/workflow-skills/create/${r?.version?.skill?.id}`, {
          state: {
            fromModal: true,
          },
        });
      } else {
        handleClose();
      }
    },
    onError(err) {
      setError(err);
    },
  });

  const handleSubmit = onSubmit(async form => {
    setError(null);
    const env = {};
    const storage = {};
    cleanInputArray(form.env).forEach(v => {
      env[v.key] = v.value;
    });
    cleanInputArray(form.storage).forEach(v => {
      storage[v.key] = v.value;
    });
    const endpointAliases = cleanInputArray(form.endpointAliases).map(v => v.alias);

    const port = parseInt(form.port);
    const shmSize = parseInt(form.shmSize);
    const writableRootFS = parseInt(form.writableRootFS);

    createSkill.mutate({
      storage,
      env,
      hostName: !isEmpty(form.hostName) ? form.hostName : null,
      endpointAliases,
      portBindings: cleanInputArray(form.portBindings),
      tmpfs: cleanInputArray(form.tmpfs),
      shmSize: _isFinite(shmSize) ? shmSize : null,
      port: _isFinite(port) ? port : null,
      removableMedia: isBoolean(form.removableMedia) ? form.removableMedia : null,
      hostNetworking: isBoolean(form.hostNetworking) ? form.hostNetworking : null,
      gpio: isBoolean(form.gpio) ? form.gpio : null,
      cx2000IRRemote: isBoolean(form.cx2000IRRemote) ? form.cx2000IRRemote : null,
      usbSerialConverter: isBoolean(form?.usbSerialConverter) ? form?.usbSerialConverter : null,
      cx2000VideoAcceleration: isBoolean(form?.cx2000VideoAcceleration)
        ? form?.cx2000VideoAcceleration
        : null,
      led: isBoolean(form?.led) ? form.led : null,
      dockerInDocker: isBoolean(form?.dockerInDocker) ? form.dockerInDocker : null,
      privileged: isBoolean(form?.privileged) ? form?.privileged : null,
      mountVolumes: cleanInputArray(form.mountVolumes),
      binds: cleanInputArray(form?.binds),
      capAdd: cleanInputArray(form?.capAdd),
      capDrop: cleanInputArray(form?.capDrop),
      addedDevice: cleanInputArray(form?.addedDevice),
      writableRootFS: _isFinite(writableRootFS) ? writableRootFS : null,
      devices: {
        ...form.devices,
        // For now, we force 5 cameras. In the future, we may allow the user to choose a number
        cameras: form.devices.cameras ? 5 : null,
      },
      version: form.version,
      repository: {
        ...form.repository,
        password: !isEmpty(form.repository.password) ? form.repository.password : undefined, // Empty password needs to return nullish
      },
      outputs: cleanInputArray(form.outputs).map(o => ({
        id: o.key,
        label: o.value,
        mapping: {
          type: o.mapping?.type || null,
          statusMapping: o.mapping?.statusMapping || null,
        },
        unhealthyTimeoutMS: _isFinite(parseInt(o.unhealthyTimeoutMS))
          ? parseInt(o.unhealthyTimeoutMS)
          : null,
      })),
      inputs: cleanInputArray(form.inputs).map(i => ({
        binding: i.key,
        label: i.value,
        type: 'text',
      })),
      skill: {
        name: form.name,
        icon: form.icon?.icon,
        color: form.icon?.color,
        iconUrl: form.icon?.iconUrl,
      },
      ...(!isEmpty(form.createOptions) && currentUser?.isSysAdmin
        ? { createOptions: JSON.parse(form.createOptions) }
        : { createOptions: null }),
    });
  });

  const saving = createSkill.isPending || uploadIconState.fetching;

  return (
    <ModalInner as="form" onSubmit={handleSubmit}>
      <ModalTitle
        title="Create Skill"
        icon="Cube"
        loading={saving}
        renderActions={() => <SkillJsonUploadButton onError={setError} setValue={setValue} />}
      />
      <ModalBody className="space-y-4">
        {error && (
          <Message variant="danger" title="Couldn't Create Skill">
            {error.message}
          </Message>
        )}
        <div>
          <SkillMetaFields loading={saving} register={register} control={control} errors={errors} />
        </div>

        <div>
          <SkillFormTabs
            tab={tab}
            onChange={setTab}
            tabs={['container', 'inputs', 'outputs', 'env', 'privileges']}
          />
        </div>

        <div>
          <ValidationError name="repository.uri" errors={errors} />
          {errors?.storage?.type === 'volumeIsAlphaNumeric' && (
            <ValidationError message="Volume name must be alphanumeric" />
          )}
          {errors?.storage?.type === 'pathIsDirectory' && (
            <ValidationError message="Mount path must be in the shape of /directory" />
          )}
        </div>

        <div>
          <SkillContainerFields
            visible={isActiveTab(tab, 'container')}
            loading={saving}
            register={register}
          />
          <SkillInputFields
            visible={isActiveTab(tab, 'inputs')}
            loading={saving}
            control={control}
          />
          <SkillOutputFields
            visible={isActiveTab(tab, 'outputs')}
            loading={saving}
            control={control}
            errors={errors}
          />
          <SkillEnvFields visible={isActiveTab(tab, 'env')} loading={saving} control={control} />
          <SkillPrivilegesFields
            visible={isActiveTab(tab, 'privileges')}
            loading={saving}
            form={form}
          />
        </div>

        <RawConfig form={form} setError={setError} loading={saving} allowView={false} />
      </ModalBody>
      <ModalFooter>
        <Button type="submit" size="xl" loading={saving}>
          Save
        </Button>
      </ModalFooter>
    </ModalInner>
  );
}
